import { combineReducers } from 'redux';

import userAuth from './userAuth';
import contract from "./contract";
import CRM from "./CRM";
import global from './global';

export const INITIAL_USER_STATE = {
  isSignedIn: false,
  token: null,
  locale: '',
  name: '',
};

export const INITIAL_GLOBAL_STATE = {
  snackbar: {
    isOpen: false
  }
}

export const INITIAL_CRM_STATE = {}

export const INITIAL_CONTACT_STATE = {}

const appReducer = combineReducers({
  user: userAuth,
  contracts: contract,
  CRMCases: CRM,
  global: global
});

export default appReducer;