import apiWrapper from "./AxiosRequestsClass";
import { parseResponse } from "./utils";
import Cookies from "js-cookie";
import _ from 'lodash';


export default class UserService {
  cancelRequests = () => {
    return apiWrapper.cancelRequests();
  };

  getToken = (user, password) => {
    const requestOptions = {
      user, password
    };

    return apiWrapper.post(`/get_token`, requestOptions)
      .then(
        response => parseResponse(response)
      )
      .catch(
        error => parseResponse(error)
      );
  };

  getUser = () => {
    return apiWrapper.get(`/users/`, {}, '/v2')
      .then(
        response => response
      )
      .catch(
        error => parseResponse(error)
      );
  };

  checkToken = (token) => {
    const requestOptions = {
      token,
    };
    return apiWrapper.post(`/is_token_valid`, requestOptions)
      .then(
        (response) => {
          return _.get(parseResponse(response)[1], 'is_token_valid', [null, false]);
        }
      ).catch(
        (error) => _.get(parseResponse(error)[1], 'token_is_valid', [null, false])
      );
  };

  signUp = (formVals) => {
    const requestOptions = {
      ...(_.omit(formVals, ['checkedB', 'checkedC'])),
      'new_customer': !formVals.checkedB,
      'is_owner': !!(formVals.checkedC)
    };
    return apiWrapper.post(`/user/signup/`, requestOptions)
      .then(
        response => parseResponse(response)
      )
      .catch(
        error => parseResponse(error)
      );
  }

  resetPassword = (current, newPass) => {
    const requestOptions = {
      current, password: newPass
    };
    return apiWrapper.put(`/user/password/`, requestOptions)
      .then(
        response => parseResponse(response)
      )
      .catch(
        error => parseResponse(error)
      );
  }

  recoverPassword = (nif, email) => {
    const requestOptions = {
      nif, email
    };
    return apiWrapper.post(`/user/reset/`, requestOptions)
      .then(
        response => parseResponse(response)
      )
      .catch(
        error => parseResponse(error)
      );
  }

  setToken = (token) => {
    Cookies.set('token', token, { secure: true, sameSite: 'Lax' } );
    apiWrapper.getApi().defaults.headers.common['Authorization'] = token;
  };

  unsetToken = () => {
    Cookies.remove('token');
    apiWrapper.getApi().defaults.headers.common['Authorization'] = '';
  }

  checkNif = (nif) => {
    return apiWrapper.get(`/user/check_nif/${nif}`)
    .then(
      response => parseResponse(response)
    )
    .catch(
      error => parseResponse(error)
    );
  }
}


