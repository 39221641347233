import React, { useState } from "react";

import {
  Button, Checkbox, Container, FormControlLabel, 
  FormGroup, Grid, Link, Paper, Typography 
} from "@material-ui/core";
import { useTranslation } from "react-i18next";


const CookiePersonalizeDialog = ({
  cookiePolicyHref,
  acceptCookies
}) => {
  const { t } = useTranslation();

  const [acceptedCookies, setAcceptedCookies] = useState({
    consent: true,
    preferences: false,
    statistics: false,
    marketing: false,
  });

  const handleChange = (event) => {
    setAcceptedCookies({ ...acceptedCookies, [event.target.name]: event.target.checked });
  };

  return (
    <Paper elevation={24} style={{padding: 30}}>
      <Typography variant="h4">{t('common:cookies_consent_title')}</Typography>
      <Typography>{t('common:cookies_consent_text')}</Typography>
      <FormGroup row style={{justifyContent: "center"}}>
        <FormControlLabel
          control={<Checkbox checked={acceptedCookies.consent} onChange={handleChange} name="consent" />}
          label={t('common:cookies_necessary_text')}
        />
        <FormControlLabel
          control={<Checkbox checked={acceptedCookies.preferences} onChange={handleChange} name="preferences" />}
          label={t('common:cookies_preferences_text')}
        />
        <FormControlLabel
          control={<Checkbox checked={acceptedCookies.statistics} onChange={handleChange} name="statistics" />}
          label={t('common:cookies_statistics_text')}
        />
        <FormControlLabel
          control={<Checkbox checked={acceptedCookies.marketing} onChange={handleChange} name="marketing" />}
          label={t('common:cookies_marketing_text')}
        />
      </FormGroup>
      <Container>
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item>
            <Link variant="button" href={cookiePolicyHref} target="_blank" rel="noreferrer">{t('common:cookies_policy_text')}</Link>
          </Grid>
          <Grid item>
            <Button
              variant={'contained'}
              className="container-element"
              onClick={() => acceptCookies(acceptedCookies)}
            >
              {t('common:cookies_consent_button')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

export default CookiePersonalizeDialog;