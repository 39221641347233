import React from "react";
import { connect } from 'react-redux';
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { Container } from "@material-ui/core";
import { signIn } from '../../actions';
import withStyles from "@material-ui/core/styles/withStyles";
import { Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { LoginLogo, theme } from '../../config';
import _ from 'lodash';
import {
  AccountCircle,
  LockRounded,
  Visibility,
  VisibilityOff
} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { LinkRouter } from "../../components";
import TextField from "@material-ui/core/TextField";
import {ResponsiveContextConsumer} from "../../contexts/ResponsiveContext";

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: "center",
    flexDirection: 'column',
    alignItems: 'center',
  },
  error: {
    color: theme.palette.error.main,
    alignItems: 'center',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    },
    flexDirection: "column",
  },
  form: {
    padding: '3em',
    paddingTop: 20,
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 50,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    textAlign: 'center'
  },
  input: {
    width: "40ch",
    [theme.breakpoints.down('sm')]: {
      width: "30ch",
    }
  },
  loginButton: {
    margin: theme.spacing(3, 0, 2)
  },
  title: {
    // padding: theme.spacing(1),
    // marginTop: theme.spacing(1)
  },
});

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: '',
      password: '',
      loggingIn: false,
      submitted: false,
      loginSuccessful: false,
      statusText: '',
      resetPasswordForm: false,
      showPassword: false,
      recoverPasswordEnabled: true,
    };
  }

  handleChange = e => {
    const {name, value} = e.target;
    this.setState({[name]: value});
  };

  statusTextFromHttpCode(statusCode, response) {
    const { t, i18n } = this.props;

    let msg = t("validation:unknown_error");
    if (200 <= statusCode && statusCode < 300) {
      msg = t("validation:login_success");
    }
    else {
      if (400 <= statusCode && statusCode < 500) {
        const error = response.errors ? _.first(_.values(response.errors)) : "unknown_error";
        if (i18n.exists("validation:" + error))
          msg = t("validation:" + error);
      }
      else if (500 <= statusCode && statusCode < 600) {
        msg = t("validation:connection_error");
      }
    }
    return msg;
  }

  onSubmit = e => {
    e.preventDefault();
    this.setState({ submitted: true });

    if (this.state.user && this.state.password) {
      this.setState({ loggingIn: true });
      this.props.signIn(this.state.user, this.state.password)
      .then(
        ([ statusCode, response ]) => {
          this.setState({
            loginSuccessful: 200 === statusCode || statusCode === 419,
            resetPasswordForm: statusCode === 419,
            loggingIn: false,
            statusText: this.statusTextFromHttpCode(statusCode, response)
          });
        }
      );
    }
  };

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  renderUsernameInput = () => {
    const { t, classes } = this.props;
    return (
      <Grid container spacing={1} alignItems="center" justify="center" style={{marginTop: theme.spacing(3)}}>
        <Grid item>
          <AccountCircle/>
        </Grid>
        <Grid item>
          <TextField
            id="outlined-adornment-user"
            label={t('nif')}
            name="user"
            variant={"outlined"}
            type="text"
            fullWidth
            className={classes.input}
            onChange={this.handleChange}
            disabled={this.state.loggingIn}
          />
        </Grid>
      </Grid>
    )
  }

  renderPasswordInput = () => {
    const { t, classes } = this.props;
    return (
      <Grid container spacing={1} alignItems="center" justify="center" style={{marginTop: theme.spacing(3)}}>
        <Grid item>
          <LockRounded/>
        </Grid>
        <Grid item>
          <TextField
            label={t("passwd")}
            name="password"
            variant={"outlined"}
            type={this.state.showPassword ? 'text' : 'password'}
            onChange={this.handleChange}
            style={{ marginBottom: theme.spacing(1) }}
            fullWidth
            disabled={this.state.loggingIn}
            className={classes.input}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                  >
                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
    )
  }

  render() {
    const { classes, location, t } = this.props;
    const {
      user, password, loginSuccessful, statusText, loggingIn, resetPasswordForm, recoverPasswordEnabled
    } = this.state;

    if (loginSuccessful) {
      let from = { pathname: '/' };
      if (resetPasswordForm) {
        from = { pathname: '/reset' };
      }
      else if (location.state && location.state.from.pathname !== '/logout') {
        from = location.state.from;
      }
      return <Redirect exact to={from}/>
    } else {
      return (
        <Container className={classes.root} maxWidth="sm">
          <ResponsiveContextConsumer>
            {
              ({ lowScreen }) => <div style={{marginTop: lowScreen ? '3vh' : '-2vh'}}><LoginLogo /></div>
            }
          </ResponsiveContextConsumer>
          <Card className={classes.contentBody} variant="outlined">
            <form
              className={classes.form}
              onSubmit={this.onSubmit}
            >
              <Typography
                className={classes.title}
                variant="h5"
              >
                {t('login_title')}
              </Typography>
              {this.renderUsernameInput()}
              {this.renderPasswordInput()}
              {
                statusText ?
                  <Typography component="div" className={classes.error}>
                    {statusText}
                  </Typography>
                  :
                  <br/>
              }
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={!(user && password) || loggingIn}
                startIcon={loggingIn ?
                  <CircularProgress size={20}/> : <SendIcon/>}
                size="large"
                type="submit"
                className={classes.loginButton}
              >
                {t('access')}
              </Button>
              <Grid container style={{display: 'flex', flexDirection: 'row', marginTop: theme.spacing(2)}}>
                <Grid item xs style={{margin: theme.spacing(1)}}>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    {t('have_account_question_negative') + '  '}
                    <br/>
                    <LinkRouter
                      to="/activacion"
                      variant="body1"
                    >
                      {t('activation')}
                    </LinkRouter>
                  </Typography>
                </Grid>
                { recoverPasswordEnabled && <Grid item xs style={{margin: theme.spacing(1)}}>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    {t('dont_remember_password') + '  '}
                    <br/>
                    <LinkRouter
                      to="/recuperar-clave"
                      variant="body1"
                    >
                      {t('recover_password')}
                    </LinkRouter>
                  </Typography>
                </Grid>}
              </Grid>
            </form>
          </Card>
        </Container>
      )
    }
  }
}

const actionCreators = {
  signIn,
};

const mapStateToProps = state => {
  return {
    isSignedIn: state.user.isSignedIn,
    statusText: state.user.statusText,
  }
};

export default compose(
  connect(mapStateToProps, actionCreators),
  withStyles(styles),
  withTranslation(['common', 'validation'])
)(Login);
