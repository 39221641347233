import React from 'react';

import { 
  Button, Container, Grid, Link, Paper, Typography 
} from "@material-ui/core";
import { useTranslation } from "react-i18next";


const CookieAcceptDialog = ({
  cookiePolicyHref,
  acceptCookies,
  personalizeCookies
}) => {
  const { t } = useTranslation();

  return (
    <Paper elevation={24} style={{ padding: 30 }}>
      <Typography variant="h4">{t('common:cookies_consent_title')}</Typography>
      <Typography>{t('common:cookies_consent_text')}</Typography>
      <Container>
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Link variant="button" href={cookiePolicyHref} target="_blank" rel="noreferrer">{t('common:cookies_policy_text')}</Link>
          </Grid>
          <Grid item>
            <Button
              color={'primary'}
              variant={'contained'}
              className="container-element"
              onClick={() => { acceptCookies("all") }}
            >
              {t('common:cookies_accept_all_text')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant={'contained'}
              className="container-element"
              onClick={personalizeCookies}
            >
              {t('common:cookies_decide_which_text')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

export default CookieAcceptDialog