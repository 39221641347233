import {
  LOCALE_CHANGE,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
  USER_PROFILE_RECEIVE,
} from '../actions/types';
import { INITIAL_USER_STATE } from "./index";

export default ( state = INITIAL_USER_STATE, action ) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isSignedIn: true,
        token: action.token,
        locale: action.locale,
      };
    case LOGIN_FAILURE:
      return {
        ...INITIAL_USER_STATE,
      };
    case LOCALE_CHANGE:
      return {
        ...state,
        locale: action.locale,
      };
    case LOGOUT:
      return INITIAL_USER_STATE;
    case USER_PROFILE_RECEIVE:
      return {
        ...state,
        name: action.name,
        email: action.email,
        phone: action.phone,
      }
    default:
      return state;
  }
}

