import Cookies from "js-cookie";

const acceptConsentCookies = () => {
  Cookies.set("rcl_consent_given", true);
};

const acceptPreferencesCookies = () => {
  Cookies.set("rcl_preferences_consent", true);
};

const acceptStatisticCookies = () => {;
  Cookies.set("rcl_statistics_consent", true);
};

const acceptMarketingCookies = () => {
  Cookies.set("rcl_marketing_consent", true);
};

const acceptAllCookies = () => {
  Cookies.set("rcl_cookies_accepted", true);
  Cookies.set("rcl_marketing_consent", true);
  Cookies.set("rcl_consent_given", true);
  Cookies.set("rcl_preferences_consent", true);
  Cookies.set("rcl_statistics_consent", true);
};

const acceptCookies = () => {
  Cookies.set("rcl_cookies_accepted", true);
};

const hasAcceptedCookies = () => {
  if (Cookies.get("rcl_cookies_accepted")) {
    return true;
  }
  return false;
};

const cookies = {
  acceptConsentCookies,
  acceptStatisticCookies,
  acceptMarketingCookies,
  acceptPreferencesCookies,
  acceptAllCookies,
  acceptCookies,
  hasAcceptedCookies
};

export default cookies;