import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import CookieAcceptDialog from './components/CookieAcceptDialog';
import CookiePersonalizeDialog from './components/CookiePersonalizeDialog';
import Settings from "../../settings.yml";
import cookies from '../../utils/Cookies';

const CookieDialog = () => {
  const [showPreferences, setShowPreferences] = useState(false);
  const [showBanner, setShowBanner] = useState(!cookies.hasAcceptedCookies());

  const acceptedCookiesMap = {
    consent: cookies.acceptConsentCookies,
    preferences: cookies.acceptPreferencesCookies,
    statistics: cookies.acceptStatisticCookies,
    marketing: cookies.acceptMarketingCookies,
  }

  const acceptCookies = (acceptedCookies) => {
    setShowBanner(false);
    if (acceptedCookies === "all") {
      cookies.acceptAllCookies();
    } else if (typeof acceptedCookies === "object") {
      for (const [key, value] of Object.entries(acceptedCookies)) {
        if(value) {
          acceptedCookiesMap[key]();
        }
      }
      cookies.acceptCookies();
    }
  };

  const personalizeCookies = () => {
    setShowPreferences(true);
  };

  return (
    <Box style={{position: "fixed", bottom: 0, width: "100%", zIndex: 10 }}>
      {showBanner && !showPreferences && <CookieAcceptDialog {...{acceptCookies, personalizeCookies, cookiePolicyHref: Settings.cookiesLink}}/>}
      {showBanner && showPreferences && <CookiePersonalizeDialog {...{acceptCookies, cookiePolicyHref: Settings.cookiesLink}}/>}
    </Box>
  );
}

export default CookieDialog;