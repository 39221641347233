import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';

import {Footer, Topbar} from './components';
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { connect } from "react-redux";
import { closeGlobalSnackbar } from "../../actions";
import CookieDialog from '../../views/CookieDialog';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "center",
    height: '100%',
  },
  container: {
    minHeight: '80vh',
    flex: '1 0 auto',
    display: 'flex',
    justifyContent: 'center'
  },
  topbar: {
    height: '12vh'
  },
  footer: {
    height: '8vh'
  }
}));

const Main = props => {
  const classes = useStyles();

  const closeSnackbar = () => {
    props.closeGlobalSnackbar();
  }

  return (
    <div className={classes.root}>
      <Topbar className={classes.topbar}/>
      <div className={classes.container}>
        { props.children }
      </div>
      <Snackbar open={props.snackbarInfo.isOpen} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert
          severity={props.snackbarInfo.error ? "error" : "success"}
          onClose={closeSnackbar}>
          { props.snackbarInfo.message }
        </Alert>
      </Snackbar>
      <CookieDialog />
      <Footer className={classes.footer}/>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

const mapStateToProps = state => {
  return {
    snackbarInfo: state.global.snackbar,
  };
};

const mapDispatchToProps = {
  closeGlobalSnackbar
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
