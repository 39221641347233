import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import { LOCALE_CHANGE, LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT, RESET_STORE, USER_PROFILE_RECEIVE } from "./types";
import { parseResponse, UserService } from "../service";
import { i18n, store } from "../config";
import { getLangByToken } from "../i18n";


const resetStore = () => {
  store.dispatch({
    type: RESET_STORE
  })
}

export const actionFetchUserProfile = () => async (dispatch) => {
  const userService = new UserService();
  userService.getUser()
  .then(
    response => {
      dispatch({
        type: USER_PROFILE_RECEIVE,
        name: response?.data?.name,
        email: response?.data?.email,
        phone: response?.data?.phone
      })
    }
  )
  .catch(
    error => {
      return parseResponse(error);
    }
  )
}


export const actionLoginUserSuccess = (token) => {
  const userService = new UserService();
  userService.setToken(token);
  return {
    type: LOGIN_SUCCESS,
    token,
    locale: jwtDecode(token).locale,
  }
};

export const actionLoginUserFailure = () => {
  return {
    type: LOGIN_FAILURE,
  }
};

export const actionChangeLocale = newLocale => async dispatch => {
  console.debug("Changing locale to ", newLocale);
  i18n.changeLanguage(newLocale)
    .then(
        dispatch({
          type: LOCALE_CHANGE,
          locale: newLocale
        })
    );
}

export const logoutUser = () => {
  const userService = new UserService();
  userService.unsetToken();
  resetStore();
  return {
    type: LOGOUT
  }
};

export const signIn = (user, password) => async dispatch => {
  const userService = new UserService();
  let [ statusCode, response ] = await userService.getToken(user, password);

  const token = _.get(response, "token", null);
  if ((200 <= statusCode || statusCode === 419) && token) {
    actionChangeLocale(getLangByToken(token));
    dispatch(actionLoginUserSuccess(token));
  }
  else {
    dispatch(actionLoginUserFailure());
  }
  return [ statusCode, response ];
};

export const signOut = () => async dispatch => {
  dispatch(logoutUser());
};

export const signUp = (formVals) => async dispatch => {
  const userService = new UserService();
  const [ statusCode, errors ] = await userService.signUp(formVals);
  return [ statusCode, errors ];
};